import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Fade,
  Divider
} from "@mui/material";
import { categoryHelper, Ingredient } from "../../../types/ingredient-type";
import { memo, forwardRef } from "react";

interface Props {
  ingredient: Ingredient;
  handleEditIngredient: (ingredient: Ingredient) => Ingredient;
  handleDeleteIngredient: (ingredient: Ingredient) => void;
}
const AddEditIngredientCardItem = (props: Props) => {
  const { ingredient, handleEditIngredient, handleDeleteIngredient } = props;
  return (
    <Card
      elevation={0}
      sx={{ backgroundColor: "secondary.main", borderRadius: 2, m: 0.5 }}
    >
      <CardContent sx={{ pb: 0 }}>
        <Typography gutterBottom variant="h5" component="div">
          {ingredient.wholeLine}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {categoryHelper(ingredient.category)}
        </Typography>
        <Divider sx={{ borderBottomWidth: 1.25, mt: 2, mb: 0 }}></Divider>
      </CardContent>

      <CardActions sx={{ display: "flex", justifyContent: "end " }}>
        <Button size="small" sx={{ color: "black" }}>
          Edit
        </Button>
        <Button
          onClick={() => handleDeleteIngredient(ingredient)}
          size="small"
          sx={{ color: "black" }}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default AddEditIngredientCardItem;
