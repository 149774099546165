import { User } from "firebase/auth";
import { useContext } from "react";
import { UserContext } from "../contexts/user.context";
import { Recipe } from "../types/recipe-type";
import { AppUser } from "../types/user-type";
import {
  addData,
  createDocumentReference
} from "../utils/firesbase/firestore.utils";

export const createNewRecipe = async (recipe: Recipe, user: User) => {
  const documentRef = createDocumentReference("_recipes");
  recipe = {
    ...recipe,
    id: documentRef.id,
    createdAt: new Date().getTime(),
    owner: {
      id: user.uid,
      email: user.email ?? ""
    },
    originalOwner: { id: user.uid, email: user.email ?? "" },
    ingredients: recipe.ingredients.map((ingredient) => ({
      ...ingredient,
      recipeId: documentRef.id
    }))
  };
  await addData({
    documentRef,
    data: recipe
  }).catch((error) => {
    console.error("Error adding document: ", error);
    return error;
  });
  console.log("created: ", documentRef.id);
};
