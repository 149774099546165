import { Box } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import LandingItem from "../../components/landing-item/landing-item.component";

const LandingItemGroup = (): JSX.Element => {
  const carrot = require("../../assets/graphics/carrot.png");
  const knife = require("../../assets/graphics/knife.png");
  const avo = require("../../assets/graphics/avo.png");

  return (
    <Grid container spacing={8}>
      <Grid xs={12} sm={4}>
        <LandingItem imagePath={avo} imageText="Store recipes"></LandingItem>
      </Grid>
      <Grid xs={12} sm={4}>
        <LandingItem
          imagePath={knife}
          imageText="Share with friends"
        ></LandingItem>
      </Grid>
      <Grid xs={12} sm={4}>
        <LandingItem imagePath={carrot} imageText="Sync lists"></LandingItem>
      </Grid>
    </Grid>
    // <Box
    //   sx={{
    //     ml: 1,
    //     mr: 1,
    //     mt: "5vh",
    //     display: "flex",
    //     justifyContent: "space-around",
    //     flexWrap: "wrap"
    //   }}
    // >
    //   <LandingItem imagePath={avo} imageText="Store recipes"></LandingItem>
    //   <LandingItem
    //     imagePath={knife}
    //     imageText="Share with friends"
    //   ></LandingItem>
    //   <LandingItem imagePath={carrot} imageText="Sync  lists"></LandingItem>
    // </Box>
  );
};

export default LandingItemGroup;
