import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChangedListener } from "../utils/firesbase/firebase.utils";
import "firebase/auth";
import { User } from "firebase/auth";
import { useNavigate } from "react-router-dom";

// create the AuthContext with an initial value of null
export const UserContext = createContext<{
  currentUser: User | null;
  setCurrentUser: Function;
}>({
  currentUser: null,
  setCurrentUser: () => null
});
export const UserProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const value = { currentUser, setCurrentUser };

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      setCurrentUser(user);

      navigate("/cookbook");
    });
    return unsubscribe;
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
