import { createContext, useContext, useEffect, useState } from "react";
import { Recipe } from "../types/recipe-type";
import { collectionStream } from "../utils/firesbase/firestore.utils";
import { UserContext } from "./user.context";

export const UserRecipesContext = createContext<{
  userRecipes: Recipe[];
  setUserRecipes: Function;
}>({
  userRecipes: [],
  setUserRecipes: () => null
});
//TODO set type of userRecipes to array of objects
export const UserRecipesProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [userRecipes, setUserRecipes] = useState<Recipe[]>([]);
  const { currentUser } = useContext(UserContext);
  const value = { userRecipes, setUserRecipes };

  useEffect(() => {
    if (currentUser != null) {
      const unsub = collectionStream({
        collectionPath: "_recipes",
        field: "owner.id",
        value: currentUser?.uid,
        callback: (documents: Recipe[]) => {
          setUserRecipes(documents);
        }
      });
      return unsub;
    }
    // return () => {
    //   second
    // }
  }, []);

  return (
    <UserRecipesContext.Provider value={value}>
      {children}
    </UserRecipesContext.Provider>
  );
};
