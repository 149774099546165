import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Grid from "@mui/material/Unstable_Grid2";
import AuthenticationCard from "../../components/authentication/authentication-card.component.";

import { AuthCardType } from "../../components/authentication/authentication-card.component.";
import LandingItemGroup from "../../components/landing-item-group/landing-item-group.component";

import "./landing-page.styles.css";

const LandingPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid sx={{ height: "100vh" }} container>
      <Grid
        sx={{
          backgroundColor: "secondary.main"
        }}
        sm={12}
        md={7}
      >
        <Box sx={{ mb: "5vh", display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              alignItems: "center",
              fontFamily: "Itim",
              fontSize: "5em",
              color: "#534F4F",
              mt: "10vh",
              mb: "1vh",
              textAlign: "center"
            }}
          >
            Fork and Eat
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {matches && <LandingItemGroup></LandingItemGroup>}
          {!matches && <AuthenticationCard></AuthenticationCard>}
        </Box>
      </Grid>
      <Grid sx={{ backgroundColor: "primary.main" }} sm={12} md={5}>
        <Box
          sx={{
            mt: { md: "15vh", sm: "25px" },
            ml: "3vw",
            mr: "3vw",
            display: "flex",
            justifyContent: "center"
          }}
        >
          {!matches && <LandingItemGroup></LandingItemGroup>}
          {matches && <AuthenticationCard></AuthenticationCard>}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
