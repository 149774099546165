import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserRecipesContext } from "../../../contexts/user-recipes.context";
import { Recipe } from "../../../types/recipe-type";

const placeholder = require("../../../assets/place_holder.png");

const Item = ({ recipe }: { recipe: Recipe }): JSX.Element => {
  const navigate = useNavigate();
  return (
    <CardActionArea>
      <Card onClick={() => navigate(`/cookbook/${recipe.id}`)} sx={{ pb: 3 }}>
        <CardMedia
          sx={{ height: 140 }}
          image={recipe.recipeImageUrl ?? placeholder}
        />{" "}
        <Box
          sx={{
            pt: 2,
            height: 260,
            display: "flex",
            alignItems: "stretch",
            flexDirection: "column"
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {recipe.title}
          </Typography>

          <Box sx={{ p: 0, flexGrow: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {recipe.description.substring(0, 160)}
            </Typography>
          </Box>
          {/* <Box sx={{}}> */}

          {recipe.recipeSource && (
            <>
              <Divider
                variant="middle"
                sx={{ mt: 1, mb: 1, mr: 0, ml: 0, borderBottomWidth: 1 }}
              />
              <Typography sx={{ ml: 0 }} variant="body2" color="text.secondary">
                {recipe.recipeSource?.authorOrWebsite}
              </Typography>
            </>
          )}

          {/* </Box> */}

          {/* <Box sx={{ flexGrow: 2 }}>Item 1</Box>
          <Box sx={{}}>Item 2</Box>
          <Box sx={{}}>Item 3</Box> */}
        </Box>
        {/* <CardMedia
          sx={{ height: 140 }}
          image={recipe.recipeImageUrl ?? placeholder}
        />

        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {recipe.title}
          </Typography>

          <Box>
            <Typography variant="body2" color="text.secondary">
              {recipe.description.substring(0, 160)}
            </Typography>
          </Box>
          <Divider
            variant="middle"
            sx={{ mt: 1, mb: 1, borderBottomWidth: 1 }}
          />
          <CardActions>
            <Typography variant="body2" color="text.secondary">
              {recipe.recipeSource?.authorOrWebsite}
            </Typography>
          </CardActions>
        </CardContent> */}
      </Card>
    </CardActionArea>
  );
};

export default Item;
