import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Camera } from "@mui/icons-material";
import { useReducer, useState, useContext, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import "./new-recipe.page.styles.css";
import AddEditIngredientCardItem from "../../components/recipe/add-edit-recipe/add-edit-ingredient-card.component";
import { Ingredient, IngredientCategory } from "../../types/ingredient-type";
import { Recipe } from "../../types/recipe-type";
import getCategory from "../../utils/parsing/IngredientsParser";
import AddEditDirectionCardItem from "../../components/recipe/add-edit-recipe/add-edit-direction-card.component";
import { createNewRecipe } from "../../adapters/recipe-adapters";
import { UserContext } from "../../contexts/user.context";
import { Direction } from "../../types/direction-type";

interface FormState {
  recipe: Recipe;
  errors: {
    title: string | undefined;
    description: string | undefined;
    ingredients: string | undefined;
    directions: string | undefined;
  };
}

interface ChangeAction {
  field: keyof Recipe | "";
  value: string;
  type:
    | "change"
    | "addIngredient"
    | "addDirection"
    | "deleteDirection"
    | "deleteIngredient"
    | "editIngredient"
    | "validate";
}

const intialFormState: FormState = {
  recipe: {
    createdAt: new Date().getTime(),
    id: "",
    title: "",
    description: "",
    ingredients: [],
    directions: [],
    owner: { email: "", id: "" },
    recipeSource: {},
    tags: [],
    recipeImageUrl: ""
  },
  errors: {
    title: undefined,
    description: undefined,
    ingredients: undefined,
    directions: undefined
  }
};

const formReducer = (state: FormState, action: ChangeAction): FormState => {
  switch (action.type) {
    case "change":
      return {
        ...state,
        recipe: {
          ...state.recipe,
          [action.field]: action.value
        },
        errors: {
          ...state.errors,
          [action.field]: undefined
        }
      };

    case "addIngredient":
      return {
        ...state,
        recipe: {
          ...state.recipe,
          ingredients: [
            ...state.recipe.ingredients,
            {
              uuid: uuidv4(),
              category: getCategory(action.value),
              wholeLine: action.value,
              recipeName: state.recipe.title,
              recipeId: "",
              purchased: false,
              fromAiScan: false
            }
          ]
        },
        errors: {
          ...state.errors,
          [action.field]: undefined
        }
      };
    case "addDirection":
      return {
        ...state,
        recipe: {
          ...state.recipe,
          directions: [
            ...state.recipe.directions,
            {
              directionText: action.value,
              step: state.recipe.directions.length + 1,
              assignedIngredients: [],
              fromAiScan: false
            }
          ]
        },
        errors: {
          ...state.errors,
          [action.field]: undefined
        }
      };

    case "deleteIngredient":
      const newIngredients = state.recipe.ingredients.filter(
        (ingredient) => ingredient.uuid !== action.value
      );

      return {
        ...state,
        recipe: {
          ...state.recipe,
          ingredients: newIngredients
        },
        errors: {
          ...state.errors,
          [action.field]: undefined
        }
      };
    case "deleteDirection":
      const newDirections = state.recipe.directions
        .filter((direction) => direction.step.toString() !== action.value)
        .map((direction, index) => ({ ...direction, step: index + 1 }));
      return {
        ...state,
        recipe: {
          ...state.recipe,
          directions: newDirections
        },
        errors: {
          ...state.errors,
          [action.field]: undefined
        }
      };

    case "editIngredient":
      //todo need to add case for remove ingredient too
      // const newIngredients = state.recipe.ingredients.push({
      //   uuid: "its the uuid",
      //   category: IngredientCategory.unknownCategory,
      //   wholeLine: action.value,
      //   recipeName: "",
      //   recipeId: "",
      //   purchased: false,
      //   fromAiScan: false
      // });

      return {
        ...state,
        recipe: {
          ...state.recipe,
          ingredients: [
            ...state.recipe.ingredients,
            {
              uuid: uuidv4(),
              category: IngredientCategory.unknownCategory,
              wholeLine: action.value,
              recipeName: "",
              recipeId: "",
              purchased: false,
              fromAiScan: false
            }
          ]
        },
        errors: { ...state.errors }
      };

    case "validate":
      const errors: FormState["errors"] = {
        title: undefined,
        description: undefined,
        ingredients: undefined,
        directions: undefined
      };
      if (!state.recipe.title) {
        errors.title = "Title is required";
      }
      if (!state.recipe.description) {
        errors.description = "Description is required";
      }
      if (!state.recipe.ingredients.length) {
        errors.ingredients = "At least one ingredient is required";
      }
      if (!state.recipe.directions.length) {
        errors.directions = "At least one direction is required";
      }
      return { ...state, errors };
    default:
      return state;
  }
};

const AddRecipePage = (): JSX.Element => {
  const [formState, dispatch] = useReducer(formReducer, intialFormState);
  const [ingredientInput, setIngredientInput] = useState("");
  const [directionInput, setDirectionInput] = useState("");
  const { currentUser } = useContext(UserContext);

  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Check if form is valid all values of the formState.errors object are undefined

    const checkAllUndefined = Object.values(formState.errors).every(
      (value) => typeof value === "undefined"
    );
    if (checkAllUndefined) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formState.errors]);

  const handleChange =
    (field: keyof Recipe) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        field,
        value: event.target.value,
        type: "change"
      });
    };

  const handleIngredientChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIngredientInput(event.target.value);
  };

  const handleDirectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDirectionInput(event.target.value);
  };

  const handleAddIngredient = (event: any) => {
    if (event.key === "Enter" && ingredientInput) {
      dispatch({
        field: "ingredients",
        value: ingredientInput,
        type: "addIngredient"
      });
      setIngredientInput("");
      // clear the input field
    }
  };

  const handleAddDirection = (event: { key: string }) => {
    if (event.key === "Enter" && directionInput) {
      dispatch({
        field: "directions",
        value: directionInput,
        type: "addDirection"
      });
      // clear the input field
      setDirectionInput("");
    }
  };

  const handleDeleteDirection = (direction: Direction) => {
    dispatch({
      field: "directions",
      value: direction.step.toString(),
      type: "deleteDirection"
    });
  };

  const handleDeleteIngredient = (ingredient: Ingredient) => {
    dispatch({
      field: "ingredients",
      value: ingredient.uuid,
      type: "deleteIngredient"
    });
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    dispatch({
      field: "",
      value: "",
      type: "validate"
    });

    if (isFormValid) {
      createNewRecipe(formState.recipe, currentUser!);
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        width: "100%"
      }}
    >
      {/* //TODO change url to show placeholder if there is no URL */}
      <div
        style={{ margin: "10px", position: "relative", textAlign: "center" }}
      >
        <Box sx={{ mt: 2 }}>
          {formState.recipe.recipeImageUrl ? (
            <img src={formState.recipe.recipeImageUrl}></img>
          ) : (
            <div style={{ backgroundColor: grey[100], height: "20em" }}></div>
          )}
        </Box>

        <Typography
          sx={{
            backgroundColor: "secondary.main",
            fontSize: { xs: "45px", md: "50px" }
          }}
          id="new-recipe-title-text"
          variant="h2"
        >
          <TextField
            label="Title"
            sx={{ width: "80%", m: 2 }}
            value={formState.recipe.title}
            onChange={handleChange("title")}
            error={!!formState.errors.title}
            helperText={formState.errors.title}
          />
        </Typography>
        <Camera id="new-recipe-camera-icon" sx={{ fontSize: "3em" }}></Camera>
      </div>
      <TextField
        sx={{ m: 1 }}
        label="Description"
        value={formState.recipe.description}
        onChange={handleChange("description")}
        error={!!formState.errors.description}
        helperText={formState.errors.description}
      />
      <Typography variant="h5" sx={{ m: 1 }}>
        Ingredients
      </Typography>

      {formState.recipe.ingredients.map((ingredient) => {
        return (
          <AddEditIngredientCardItem
            key={ingredient.uuid}
            ingredient={ingredient}
            handleEditIngredient={function (): Ingredient {
              throw new Error("Function not implemented.");
            }}
            handleDeleteIngredient={handleDeleteIngredient}
          />
        );
      })}

      <TextField
        sx={{ m: 1 }}
        value={ingredientInput}
        label="Add Ingredient"
        onChange={handleIngredientChange}
        onKeyDown={handleAddIngredient}
        error={!!formState.errors.ingredients}
        helperText="Enter to add ingredient"
      />

      <Typography variant="h5" sx={{ m: 1 }}>
        Directions
      </Typography>
      {formState.recipe.directions.map((direction) => {
        return (
          <AddEditDirectionCardItem
            key={direction.step}
            direction={direction}
            handleEditDirection={handleDeleteDirection}
            handleDeleteDirection={handleDeleteDirection}
          />
        );
      })}

      <TextField
        sx={{ m: 1 }}
        value={directionInput}
        label="Add Direction"
        onChange={handleDirectionChange}
        onKeyDown={handleAddDirection}
        error={!!formState.errors.directions}
        helperText="Enter to add a Direction"
      />
      <Button type="submit" onClick={handleSubmit}>
        Save
      </Button>
    </Container>
  );
};

export default AddRecipePage;
