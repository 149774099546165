import { FirebaseError } from "firebase/app";
import { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography
} from "@mui/material";

import GoogleButton from "react-google-button";

import {
  createAuthUserWithEmailAndPassword,
  signInWithEmailandPw,
  signInWithGooglePopup
} from "../../utils/firesbase/firebase.utils";

export enum AuthCardType {
  signin,
  signup
}

interface IAuthenticationCardProps {
  authCardType: AuthCardType;
}

const defaultFormFields = {
  displayName: "",
  email: "",
  password: "",
  confirmPassword: ""
};

const AuthenticationCard = () => {
  const [formType, setFormType] = useState(AuthCardType.signin);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formMessage, setFormMessage] = useState<string | null>(null);
  const [formTitle, setFormTitle] = useState<string | null>(null);

  useEffect(() => {
    switch (formType) {
      case AuthCardType.signin:
        setFormMessage("Already have an account? Sign Up");
        setFormTitle("Sign In");
        break;

      case AuthCardType.signup:
        setFormMessage("Need an account? Sign In");
        setFormTitle("Sign Up");
        break;
    }
  }, [formType]);

  const changeFormType = () => {
    console.log("click");
    setFormType(
      formType === AuthCardType.signin
        ? AuthCardType.signup
        : AuthCardType.signin
    );
  };

  const onSubmit = async (event: { preventDefault: () => void }) => {
    event?.preventDefault();
    console.log("submitted", formFields);
    try {
      switch (formType) {
        case AuthCardType.signin:
          await signInWithEmailandPw(formFields.email, formFields.password);
          break;
        case AuthCardType.signup:
          if (formFields.password !== formFields.confirmPassword) {
            setErrorMessage("Passwords do not match");
            setTimeout(() => {
              //reset error message after 3 seconds
              setErrorMessage(null);
            }, 3000);
            return;
          }
          await createAuthUserWithEmailAndPassword(
            formFields.email,
            formFields.password
          );
          resetFormFields();
          break;
      }
    } catch (error) {
      // show error if its a firebase authentication error
      if (error instanceof FirebaseError) {
        setErrorMessage(error.message);
        setTimeout(() => {
          //reset error message after 3 seconds
          setErrorMessage(null);
        }, 3000);
      } else {
        console.log(error);
      }
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const resetFormFields = () => setFormFields(defaultFormFields);

  const googleAuth = async () => {
    await signInWithGooglePopup();
  };

  return (
    <Card variant="outlined" sx={{ m: 1, maxWidth: "600px" }}>
      <CardContent>
        <Typography sx={{ fontFamily: "Itim", fontSize: "2em", mb: 2 }}>
          {formTitle}
        </Typography>

        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        <form onSubmit={onSubmit}>
          <TextField
            name="email"
            required
            label="Email"
            variant="outlined"
            onChange={handleChange}
            sx={{ mt: 2, mb: 1, width: "100%" }}
          />
          <TextField
            name="password"
            required
            label="Password"
            type="password"
            variant="outlined"
            onChange={handleChange}
            sx={{ mt: 2, mb: 2, width: "100%" }}
          />

          {formType === AuthCardType.signup && (
            <TextField
              name="confirmPassword"
              required
              label="Confirm Password"
              type="password"
              variant="outlined"
              sx={{ mt: 1, mb: 2, width: "100%" }}
            />
          )}
          <Button type="submit" variant="contained" disableElevation>
            Submit
          </Button>
        </form>

        <Typography
          variant="subtitle2"
          onClick={changeFormType}
          sx={{ mt: 2, textAlign: "center", cursor: "pointer" }}
        >
          {formMessage}
        </Typography>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box>
          <GoogleButton onClick={googleAuth} />
        </Box>
      </CardContent>
    </Card>
  );
};
export default AuthenticationCard;
