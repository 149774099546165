import { CategoryScore } from "../../types/categoryScore";
import {
  categoryHelperEnum,
  IngredientCategory
} from "../../types/ingredient-type";

export const Dairy = [
  "butter",
  "butter",
  "eggs",
  "egg",
  "milk",
  "parmesan",
  "cheddar",
  "cream",
  "sour",
  "cream",
  "cream",
  "cheese",
  "mozzarella",
  "american",
  "cheese",
  "yogurt",
  "evaporated",
  "milk",
  "condensed",
  "milk",
  "whipped cream",
  "half and half",
  "monterey",
  "jack",
  "cheese",
  "feta",
  "cottage",
  "cheese",
  "ice",
  "cream",
  "goat",
  "cheese",
  "frosting",
  "swiss",
  "cheese",
  "buttermilk",
  "velveeta",
  "ricotta",
  "powdered",
  "milk",
  "blue",
  "cheese",
  "provolone",
  "colby",
  "cheese",
  "gouda",
  "pepper",
  "jack",
  "italian",
  "cheese",
  "soft",
  "cheese",
  "romano",
  "brie",
  "pepperjack",
  "cheese",
  "custard",
  "cheese",
  "soup",
  "pizza",
  "cheese",
  "ghee",
  "pecorino",
  "cheese",
  "gruyere",
  "creme",
  "fraiche",
  "neufchatel",
  "muenster",
  "asiago",
  "queso",
  "fresco",
  "cheese",
  "hard",
  "cheese",
  "havarti",
  "cheese",
  "mascarpone"
];
const Vegetables = [
  "crushed tomatoes",
  "tomatoes",
  "bean sprouts",
  "celery",
  "celery",
  "celery",
  "rocket",
  "eggplant",
  "egg plant",
  "garlic",
  "cloves of garlic",
  "garlic",
  "onion",
  "olive",
  "tomato",
  "potato",
  "potatoes",
  "kumara",
  "salad",
  "greens",
  "carrot",
  "basil",
  "fresh basil",
  "parsley",
  "rosemary",
  "bell",
  "pepper",
  "chilies",
  "fennel",
  "seeds",
  "red pepper",
  "corn",
  "ginger",
  "mushrooms",
  "broccoli",
  "spinach",
  "green",
  "beans",
  "celery",
  "red onions",
  "cilantro",
  "cucumbers",
  "pickles",
  "dill",
  "avocado",
  "sweet potato",
  "zucchini",
  "shallot",
  "mixed vegetable",
  "cabbage",
  "asparagus",
  "cauliflower",
  "mint",
  "pumpkin",
  "kale",
  "frozen",
  "vegetables",
  "scallion",
  "squash",
  "sun dried tomato",
  "horseradish",
  "sweet",
  "corn",
  "beet"
];
const Fruit = [
  "lemons",
  "lemon",
  "bananas",
  "apples",
  "coconut",
  "mangos",
  "limes",
  "oranges",
  "pineapples",
  "strawberries",
  "raisin",
  "blueberries",
  "grapefruits",
  "honeydew",
  "grapes",
  "prunes",
  "nectarines",
  "figs",
  "peaches",
  "cranberries",
  "raspberries",
  "pears",
  "cherry",
  "apricots",
  "blackberries",
  "berries",
  "date",
  "watermelon",
  "kiwi",
  "craisins",
  "mandarin",
  "cantaloupe",
  "plum",
  "papaya",
  "pomegranate",
  "apple",
  "butter",
  "clementine",
  "rhubarb",
  "tangerine",
  "sultanas",
  "currant",
  "plantain",
  "passion",
  "fruit",
  "persimmons",
  "quince",
  "lychee",
  "tangelos",
  "lingonberry",
  "kumquat",
  "boysenberry",
  "star",
  "fruit",
  "guava"
];
const Spices = [
  "seasoning",
  "italian seasoning",
  "red pepper flake",
  "chilli flakes",
  "dried chilli flakes",
  "cinnamon",
  "chive",
  "vanilla",
  "garlic powder",
  "oregano",
  "paprika",
  "cayenne",
  "cayenne pepper",
  "pepper",
  "chili powder",
  "cumin",
  "italian seasoning",
  "thyme",
  "peppercorn",
  "nutmeg",
  "onion powder",
  "curry powder",
  "clove",
  "bay",
  "leaf",
  "taco seasoning",
  "sage",
  "ground nutmeg",
  "chinese five spice",
  "allspice",
  "turmeric",
  "ground coriander",
  "cajun seasoning",
  "steak seasoning",
  "herbs",
  "celery",
  "salt",
  "vanilla essence",
  "poultry seasoning",
  "marjoram",
  "tarragon",
  "cardamom",
  "celery",
  "seed",
  "garam masala",
  "mustard",
  "seed",
  "chile",
  "powder",
  "italian",
  "herbs",
  "saffron",
  "caraway",
  "herbes de provence",
  "italian spice",
  "star",
  "anise",
  "dill",
  "seed",
  "aniseed",
  "cacao",
  "tamarind"
];
const Meat = [
  "fish",
  "fillet",
  "chicken breast",
  "checken",
  "breast",
  "ground beef",
  "bacon",
  "sausage",
  "cooked chicken",
  "ham",
  "veal",
  "beef",
  "steak",
  "hot dog",
  "pork chops",
  "chicken thighs",
  "ground turkey",
  "pork",
  "turkey",
  "pepperoni",
  "whole",
  "chicken",
  "chicken leg",
  "ground pork",
  "chicken",
  "wings",
  "chorizo",
  "polish sausages",
  "sweet italian sausages",
  "salami",
  "pork",
  "roast",
  "ground chicken",
  "pork ribs",
  "venison",
  "spam",
  "lamb",
  "pork shoulder",
  "beef roast",
  "bratwurst",
  "prosciutto",
  "chicken roast",
  "bologna",
  "corned beef",
  "lamb chops",
  "ground lamb",
  "beef ribs",
  "duck",
  "pancetta",
  "beef",
  "liver",
  "leg of lamb",
  "chicken giblets",
  "beef shank",
  "pork belly",
  "cornish hen",
  "lamb shoulder",
  "lamb shank"
];
const PastaAndRice = [
  "spaghetti",
  "spaghetti",
  "fettuccine",
  "penne",
  "dry pasta",
  "pasta",
  "basmati",
  "noodles",
  "rice",
  "rice"
];
const Condiments = [
  "mayonnaise",
  "sauces",
  "sauce",
  "sauce",
  "mustard",
  "ketchup",
  "vinegar",
  "balsamic",
  "vinegar",
  "wine",
  "vinegar",
  "cider",
  "vinegar",
  "rice vinegar",
  "mirin",
  "apple",
  "cider",
  "vinegar",
  "fish",
  "sauce",
  "blue",
  "cheese",
  "dressing"
];
const Oils = [
  "vegetable oil",
  "olive oil",
  "peanut oil",
  "cooking spray",
  "shortening",
  "lard",
  "oil",
  "oil",
  "grape seed oil"
];
const BakingGoods = [
  "sugar",
  "wheat",
  "germ",
  "flour",
  "whole",
  "wheat",
  "flour",
  "bread",
  "baking powder",
  "bread flour",
  "baking soda",
  "bread crumbs",
  "bran",
  "cornstarch",
  "rolled",
  "oats",
  "yeast",
  "cracker",
  "quinoa",
  "pancake",
  "mix",
  "flour",
  "tortillas",
  "cornmeal",
  "chips",
  "saltines",
  "popcorn",
  "self rising",
  "flour",
  "corn tortillas",
  "stuffing",
  "mix",
  "biscuits",
  "couscous",
  "pie",
  "crust",
  "pita",
  "bisquick",
  "cereal",
  "croutons",
  "baguette",
  "pizza",
  "dough",
  "barley",
  "puff",
  "pastry",
  "cake",
  "mix",
  "bagel",
  "pretzel",
  "wheat",
  "multigrain",
  "bread",
  "crescent",
  "roll",
  "dough",
  "pierogi",
  "bun",
  "filo",
  "dough",
  "wheat",
  "muffin mix",
  "gnocchi",
  "bread dough",
  "potato flakes",
  "rye",
  "croissants",
  "shortcrust pastry",
  "ciabatta",
  "breadsticks"
];

const JarsAndCannedGoods = [
  "olives",
  "olives",
  "stock",
  "stock",
  "stock",
  "chickpeas",
  "chickpeas",
  "lentils"
];

const getCategory = function (line) {
  let scores = {
    dairy: 0,
    vegetables: 0,
    fruit: 0,
    spices: 0,
    meat: 0,
    pastaAndRice: 0,
    condiment: 0,
    oils: 0,
    bakingGoods: 0,
    jarsAndCannedGoods: 0
  };
  let lowerCaseLine = line.toLowerCase();
  for (const b of Dairy) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.dairy++;
    }
  }
  for (const b of Vegetables) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.vegetables++;
    }
  }
  for (const b of Fruit) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.fruit++;
    }
  }
  for (const b of Spices) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.spices++;
    }
  }
  for (const b of Meat) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.meat++;
    }
  }
  for (const b of PastaAndRice) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.pastaAndRice++;
    }
  }
  for (const b of Condiments) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.condiment++;
    }
  }
  for (const b of Oils) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.oils++;
    }
  }
  for (const b of BakingGoods) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.bakingGoods++;
    }
  }
  for (const b of JarsAndCannedGoods) {
    if (lowerCaseLine.indexOf(b) >= 0) {
      scores.jarsAndCannedGoods++;
    }
  }

  let maxKey = Object.keys(scores).reduce((a, b) =>
    scores[a] > scores[b] ? a : b
  );

  return categoryHelperEnum(maxKey);
};

export default getCategory;
