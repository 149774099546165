import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { signOutUser } from "../../utils/firesbase/firebase.utils";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";

import cleaver from "../../assets/cleaver_white.png";

const NavigationBar = (): JSX.Element => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const navigateHome = () => navigate("/cookbook");
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ height: 30, mr: 2 }}>
            <img
              className="pointer"
              onClick={navigateHome}
              style={{ width: "auto", height: "100%" }}
              src={cleaver}
              alt="forkneat logo"
            ></img>
          </Box>
          <Typography
            className="pointer"
            onClick={navigateHome}
            variant="h6"
            component="div"
            sx={{ fontWeight: "light", fontSize: "1.5em" }}
          >
            ForknEat
          </Typography>

          <Box sx={{ flexGrow: 1 }}></Box>
          {currentUser ? (
            <Button
              onClick={signOutUser}
              sx={{ fontWeight: "light", fontSize: "1.2em" }}
              color="inherit"
            >
              Sign Out
            </Button>
          ) : (
            <Button color="inherit">Sign In</Button>
          )}
        </Toolbar>
      </AppBar>
      <Container>
        <Outlet></Outlet>
      </Container>
    </>
  );
};

export default NavigationBar;
