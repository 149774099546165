//react component that receives a direction object and renders it

import { Direction } from "../../types/direction-type";
import { Box, Divider, Paper, Typography } from "@mui/material";

import "./direction.styles.css";
import { Ingredient } from "../../types/ingredient-type";

const DirectionCard = ({
  direction,
  ingredients
}: {
  direction: Direction;
  ingredients: Ingredient[];
}): JSX.Element => {
  // build list of ingredients that are assigned to this direction
  // uses the id from the ingredient list to find the the ingredient name
  const assignedIngredients: string = direction.assignedIngredients
    .map((ingredientId: string) => {
      return ingredients.find(
        (ingredient: Ingredient) => ingredient.uuid === ingredientId
      )?.wholeLine;
    })
    .join(" | ");

  return (
    <Box sx={{ mt: 2, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }} className="direction-text-box">
        <Typography sx={{ textAlign: "Center" }} variant="h4">
          Step {direction.step}
        </Typography>
        <Divider
          sx={{ borderBottomWidth: 2, mt: 2, mb: 2, ml: 4, mr: 4 }}
        ></Divider>
        <Typography
          variant="body1"
          sx={{ fontWeight: "light", fontSize: "1.5em" }}
        >
          {" "}
          {direction.directionText}
        </Typography>
      </Paper>
      {assignedIngredients && (
        <Paper elevation={0} sx={{ mt: 2, backgroundColor: "secondary.main" }}>
          <Typography
            variant="body1"
            sx={{
              p: 2,
              color: "black",
              fontWeight: "light",
              fontSize: "1.5em"
            }}
          >
            {assignedIngredients}
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default DirectionCard;
