export enum IngredientCategory {
  dairy,
  vegetables,
  fruit,
  spices,
  meat,
  pastaAndRice,
  condiment,
  oils,
  bakingGoods,
  jarsAndCannedGoods,
  unknownCategory,
  extras
}

export interface Ingredient {
  uuid: string;
  category: IngredientCategory;
  wholeLine: string;
  recipeName: string;
  recipeId: string;
  purchased: boolean;
  fromAiScan: boolean;
}

export const categoryHelper = (enumCategory: number): string => {
  switch (enumCategory) {
    case IngredientCategory.dairy:
      return "Dairy";
    case IngredientCategory.vegetables:
      return "Vegetables";
    case IngredientCategory.fruit:
      return "Fruit";
    case IngredientCategory.spices:
      return "Spices";
    case IngredientCategory.meat:
      return "Meat";
    case IngredientCategory.pastaAndRice:
      return "Pasta and Rice";
    case IngredientCategory.condiment:
      return "Condiments";
    case IngredientCategory.oils:
      return "Oils";
    case IngredientCategory.bakingGoods:
      return "Baking Goods";
    case IngredientCategory.jarsAndCannedGoods:
      return "Jars and Canned Goods";
    case IngredientCategory.unknownCategory:
      return "Unknown";
    case IngredientCategory.extras:
      return "Extras";

    default:
      return "Unknown";
  }
};
export const categoryHelperEnum = (category: string): IngredientCategory => {
  switch (category) {
    case "dairy":
      return IngredientCategory.dairy;
    case "vegetables":
      return IngredientCategory.vegetables;
    case "fruit":
      return IngredientCategory.fruit;
    case "spices":
      return IngredientCategory.spices;
    case "meat":
      return IngredientCategory.meat;
    case "pasta and Rice":
      return IngredientCategory.pastaAndRice;
    case "condiments":
      return IngredientCategory.condiment;
    case "oils":
      return IngredientCategory.oils;
    case "baking Goods":
      return IngredientCategory.bakingGoods;
    case "jars and Canned Goods":
      return IngredientCategory.jarsAndCannedGoods;
    case "unknown":
      return IngredientCategory.unknownCategory;
    case "extras":
      return IngredientCategory.extras;

    default:
      return IngredientCategory.unknownCategory;
  }
};
