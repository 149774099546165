import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    tonalOffset: 0,
    primary: {
      main: "#C0E0BA"
    },
    secondary: {
      main: "#f8efde"
    }
  },
  typography: {
    fontFamily: ["Roboto", "Montserrat", "sans-serif"].join(",")
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#f7f7f8"
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          color: "#003B5C"
        }
      }
    }
  }
});
