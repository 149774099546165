// landing page component with image above text

import { Box, Typography } from "@mui/material";

const landingItem = ({
  imagePath,
  imageText,
  bottomItem
}: {
  imagePath: string;
  imageText: string;
  bottomItem?: boolean;
}) => (
  <Box
    sx={{
      p: 2,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    <Box sx={{ height: "10vw", minHeight: "100px" }}>
      <img height={"100%"} src={imagePath}></img>
    </Box>
    <Typography
      sx={{
        mt: 4,

        textAlign: "center",
        fontFamily: "Itim",
        fontSize: "2em"
      }}
    >
      {imageText}
    </Typography>
  </Box>
);

export default landingItem;
