import { initializeApp } from "firebase/app";
import {
  DocumentSnapshot,
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  QuerySnapshot,
  doc,
  setDoc,
  DocumentReference
} from "firebase/firestore";

import firebaseConfig from "./firebase.config";

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

interface CollectionStreamParams {
  collectionPath: string;
  field: string;
  value: any;
  callback: Function;
}
const db = getFirestore(firebaseApp);

export const collectionStream = (params: CollectionStreamParams) => {
  const { collectionPath, field, value, callback } = params;
  const q = query(collection(db, collectionPath), where(field, "==", value));
  const unsubscribe = onSnapshot(q, (querySnapshot: QuerySnapshot) => {
    const documents: object[] = [];
    querySnapshot.forEach((doc) => {
      documents.push(doc.data());
    });
    callback(documents);
  });

  return unsubscribe;
};

interface addDataParameters {
  documentRef: DocumentReference;
  data: { [key: string]: any };
}

// function to set data to firestore
export const addData = async (params: addDataParameters) => {
  const { documentRef, data } = params;
  //if there is a document Id created use that to set data, if not create a new document
  // let documentRef: DocumentReference;
  try {
    console.log("trying to add data");

    await setDoc(documentRef, data);
  } catch (error) {
    console.log("addData", error);
    return error;
  }
};

export const createDocumentReference = (collectionPath: string) => {
  return doc(collection(db, collectionPath));
};
