import { Routes, Route } from "react-router-dom";
import AuthGuard from "./components/auth-guard/auth-guard";

import PageNotFound from "./pages/page-not-found/page-not-found";

import Landing from "./pages/landing/landing-page.page";

import { UserRecipesProvider } from "./contexts/user-recipes.context";
import Cookbook from "./pages/home/cookbook";
import Navigation from "./components/navigation/navigation-component";
import Recipe from "./pages/recipe/recipe.page";
import NewRecipePage from "./pages/new-recipe/new-recipe.page";

import "./App.css";

function App() {
  return (
    <Routes>
      <Route index element={<Landing />} />
      <Route
        path="/"
        element={
          <AuthGuard>
            <UserRecipesProvider>
              <Navigation />
            </UserRecipesProvider>
          </AuthGuard>
        }
      >
        <Route path="cookbook" element={<Cookbook></Cookbook>}></Route>
        <Route path="cookbook/:recipeId" element={<Recipe></Recipe>} />
        <Route path="cookbook/new" element={<NewRecipePage></NewRecipePage>} />
      </Route>

      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
