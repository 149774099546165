import { useContext } from "react";
import { useParams } from "react-router-dom";
import { UserRecipesContext } from "../../contexts/user-recipes.context";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

import DirectionCard from "../../components/direction/direction.component";

import { Ingredient } from "../../types/ingredient-type";
import { Direction } from "../../types/direction-type";

import "./recipe.page.styles.css";

const RecipePage = (): JSX.Element => {
  const { recipeId } = useParams();
  const { userRecipes } = useContext(UserRecipesContext);
  const recipe = userRecipes.find((recipe) => recipe.id === recipeId);

  return (
    <>
      <Box
        sx={{ mt: 2, mb: 3, display: "flex", justifyContent: "center" }}
      ></Box>
      <Grid container spacing={2}>
        <Grid xs={12} md={7}>
          <div style={{ position: "relative", textAlign: "center" }}>
            <img className="recipe-image" src={recipe?.recipeImageUrl}></img>
            <Typography
              sx={{
                backgroundColor: "secondary.main",
                fontSize: { xs: "45px", md: "50px" }
              }}
              id="title-text"
              variant="h2"
            >
              {recipe?.title}
            </Typography>
          </div>
        </Grid>

        <Grid xs={12} md={5}>
          <Box
            sx={{ display: "flex", justifyContent: "start", flexWrap: "wrap" }}
          >
            <Typography sx={{ fontWeight: "light", mb: 2 }} variant="h5">
              {recipe?.description}
            </Typography>
          </Box>
        </Grid>

        <Typography sx={{ mb: 3, mt: 1, ml: 1 }} variant="h4">
          Ingredients
        </Typography>

        <Grid
          container
          sx={{
            p: 2,
            ml: 1,
            mr: 1,
            backgroundColor: "secondary.main",
            borderRadius: 5
          }}
        >
          {recipe?.ingredients.map((ingredient: Ingredient) => (
            <Grid
              xs={12}
              md={6}
              key={ingredient.uuid}
              sx={{
                mt: 1,
                mb: 0,
                height: "fit-content"
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "light",
                  fontSize: "1.5em",
                  wordWrap: "break-word"
                }}
              >
                {ingredient.wholeLine}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Typography sx={{ mb: 2, mt: 4 }} variant="h4">
        Directions
      </Typography>

      {recipe?.directions.map((direction: Direction) => (
        <DirectionCard
          key={direction.step}
          direction={direction}
          ingredients={recipe.ingredients}
        ></DirectionCard>
      ))}
    </>
  );
};

export default RecipePage;
