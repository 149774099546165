const firebaseConfig = {
  apiKey: "AIzaSyB3XPl7APn4MlPxBe2GLfwveJQt9a2b83E",
  authDomain: "the-wooden-spoon-3f0a6.firebaseapp.com",
  databaseURL: "https://the-wooden-spoon-3f0a6.firebaseio.com",
  projectId: "the-wooden-spoon-3f0a6",
  storageBucket: "the-wooden-spoon-3f0a6.appspot.com",
  messagingSenderId: "390342683969",
  appId: "1:390342683969:web:ce8536c777d8cecb6b05c0",
  measurementId: "G-73QB05WH2P"
};

export default firebaseConfig;
