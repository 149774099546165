import Grid from "@mui/material/Unstable_Grid2";
import { useContext } from "react";
import { UserRecipesContext } from "../../contexts/user-recipes.context";

import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import RecipeCard from "../../components/recipe/recipe-card/recipe-card.component";
import { useNavigate } from "react-router-dom";

const Cookbook = (): JSX.Element => {
  const { userRecipes } = useContext(UserRecipesContext);
  const navigate = useNavigate();
  return (
    <>
      <Grid container rowSpacing={0} columnSpacing={2}>
        {userRecipes.map((recipe) => (
          <Grid key={recipe.id} sx={{ mt: 3 }} xs={6} sm={4} md={3}>
            <RecipeCard key={recipe.id} recipe={recipe} />
          </Grid>
        ))}
      </Grid>
      <Fab
        sx={{
          position: "fixed",
          borderRadius: "20%",
          bottom: (theme) => theme.spacing(5),
          right: (theme) => theme.spacing(5)
        }}
        color="primary"
        aria-label="add"
        onClick={() => navigate("/cookbook/new")}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default Cookbook;
