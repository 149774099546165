import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from "@mui/material";
import { Direction } from "../../../types/direction-type";
import { Ingredient } from "../../../types/ingredient-type";

interface Props {
  direction: Direction;

  handleEditDirection: (direction: Direction) => void;
  handleDeleteDirection: (direction: Direction) => void;
}

const AddEditDirectionCardItem = (props: Props) => {
  const { direction, handleEditDirection, handleDeleteDirection } = props;
  return (
    <>
      <Card
        elevation={0}
        sx={{ backgroundColor: "secondary.main", borderRadius: 2, m: 0.5 }}
      >
        <CardContent>
          <Typography sx={{ textAlign: "center" }} variant="h5">
            Step: {props.direction.step}
          </Typography>
          <Divider sx={{ borderBottomWidth: 1.5, m: 2 }}></Divider>
          <Typography variant="body2" color="text.secondary">
            {props.direction.directionText}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "end " }}>
          <Button
            sx={{ color: "black" }}
            size="small"
            onClick={() => handleEditDirection(direction)}
          >
            Edit
          </Button>
          <Button
            sx={{ color: "black" }}
            size="small"
            onClick={() => handleDeleteDirection(direction)}
          >
            Delete
          </Button>
        </CardActions>
      </Card>

      {direction.assignedIngredients.length === 0 ? (
        <Card
          elevation={0}
          sx={{ backgroundColor: "primary.main", borderRadius: 2, m: 0.5 }}
        >
          <CardContent>
            <Typography
              sx={{ textAlign: "center" }}
              variant="subtitle1"
              component="div"
            >
              Click to Assign Ingredients
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Card
          elevation={0}
          sx={{ backgroundColor: "primary.main", borderRadius: 2, m: 0.5 }}
        >
          <CardContent>
            <Typography variant="h5" component="div">
              {direction.assignedIngredients}
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AddEditDirectionCardItem;
