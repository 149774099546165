import * as React from "react";
import Container from "@mui/material/Container";

import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return <h1>Page not found!</h1>;
}
