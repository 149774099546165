import { useEffect, useContext, ReactNode } from "react";
import { redirect, useNavigate } from "react-router-dom";

import { UserContext } from "../../contexts/user.context";
export default function AuthGuard({ children }: { children: JSX.Element }) {
  const { currentUser } = useContext(UserContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser === null) {
      // this would run if there is no user
      navigate("/");
    }
  }, [navigate, currentUser]); // current user being in here was meant to update every time there is a new current user
  return children;
}
